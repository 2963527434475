import React from "react";
import { ProgressBar } from "react-bootstrap";

const ProgressCard = ({ extraStyle = false, progressCount }) => {
  return (
    <div className={`progress_strip ${ extraStyle ? 'p-0 m-0 position-static' : ''}`}>
      <p>
        Progress <span>{progressCount || 0}%</span>
      </p>
      <ProgressBar variant="danger" now={progressCount} />
    </div>
  );
};

export default ProgressCard;
