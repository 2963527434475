import * as userActions from "./userAction";

export const userFeatureKey = "userInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  loggedUser: {},
  users: [],
  userDetails: {},
  isVerified: false,
  isUserUpdateStatus: false,
  devices: [],
  invoices: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // login user
    case userActions.LOGIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.LOGIN_USER_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        loading: false,
        loggedUser: payload.data,
      };

    case userActions.LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        users: [],
      };

    // register user
    case userActions.REGISTER_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedUser: payload.data,
      };

    case userActions.REGISTER_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        users: [],
      };

    // register user
    case userActions.VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        isVerified: false,
      };

    case userActions.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isVerified: true,
      };

    case userActions.VERIFY_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        isVerified: false,
      };

    // login user via email

    case userActions.LOGIN_USER_VIA_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.LOGIN_USER_VIA_EMAIL_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        loading: false,
        loggedUser: payload.data,
      };

    case userActions.LOGIN_USER_VIA_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        users: [],
      };

    case userActions.RE_LOGIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.RE_LOGIN_USER_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        loading: false,
        loggedUser: payload.data,
      };

    case userActions.RE_LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        users: [],
      };

    // update user

    case userActions.UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userActions.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: payload.data,
        isUserUpdateStatus: true,
      };
    case userActions.UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        users: [],
        userDetails: {},
      };

    // get user

    case userActions.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: payload,
      };

    case userActions.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // check user for update

    case "IS_USER_UPDATE":
      return {
        ...state,
        isUserUpdateStatus: false,
      };

    // user logout

    case userActions.LOGOUT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userActions.LOGOUT_USER_SUCCESS:
      localStorage.removeItem("token");
      return {
        ...state,
        loading: false,
        loggedUser: {},
        users: [],
        userDetails: {},
      };
    case userActions.LOGOUT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        loggedUser: {},
        users: [],
        userDetails: {},
      };

    case userActions.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userActions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userActions.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case userActions.CHECK_VALIDATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userActions.CHECK_VALIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userActions.CHECK_VALIDATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case userActions.CANCEL_MEMBERSHIP_REQUEST:
      return {
        ...state,
        loading: true,
        isUserUpdateStatus: false,
      };
    case userActions.CANCEL_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        isUserUpdateStatus: true,
      };
    case userActions.CANCEL_MEMBERSHIP_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case userActions.GET_MY_INVOICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userActions.GET_MY_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: payload.data,
      };
    case userActions.GET_MY_INVOICES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    //get free trail

    case userActions.GET_FREE_TRAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userActions.GET_FREE_TRAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: payload,
      };
    case userActions.GET_FREE_TRAIL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
