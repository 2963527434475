import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { dragIcon } from "../assets/svg/Svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import {
    getUniversitiesDropdown,
    getUniversityDegreeDropdown,
    getUniversityYearDropdown,
    getTopicsList,
  } from "../redux/dropdown/dropdownAction";
  import {
        getStudyPlannerList,
        updateStudyPlannerList,
        createStudyPlannerList
    } from "../redux/studyPlanner/studyPlannerAction";

const StudyPlanner = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState(new Date());
    // const [tasks, setTasks] = useState(["Data Structures and Algorithms", "Operating Systems", "Web Development", "Artificial Intelligence", "Database Management Systems", "Software Engineering", "Cybersecurity Fundamentals"]);
    const [calendarTasks, setCalendarTasks] = useState({});
    const { universities, degree, years, topics } = useSelector(
        (state) => state.dropdownInfo
    );
    const { studyPlannerList, isUpdate, isCreate } = useSelector(
        (state) => state.studyPlannerInfo
    );
    const [universityId, setUniversityId] = useState("");
    const [degreeId, setDegreeId] = useState("");
    const [yearId, setYearId] = useState("");
    const [subjectId, setSubjectId] = useState("");
    
    console.log('universityId', universityId.toString())
    console.log('degreeId', degreeId.toString())
    console.log('yearId', yearId.toString())
    console.log('subjectId', subjectId.toString())

    const locale = navigator.language || "en-US";

    const weekdays = Array.from({ length: 7 }, (_, i) => new Intl.DateTimeFormat(locale, { weekday: "short" }).format(new Date(1970, 0, 4 + i)));

    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const startDay = firstDayOfMonth.getDay();

    const days = Array.from({ length: lastDayOfMonth.getDate() }, (_, i) => new Date(currentDate.getFullYear(), currentDate.getMonth(), i + 1));

    const emptyStartDays = Array.from({ length: startDay }, () => null);

    const calendarDays = [...emptyStartDays, ...days];

    const handlePrevMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const handleDragStart = (event, task) => {
        event.dataTransfer.setData("task", task._id);
    };

    const handleDrop = (event, dateKey) => {
        const task = event.dataTransfer.getData("task");
        dispatch(createStudyPlannerList({
            topicId: task,
            date: dateKey
        }));
        setCalendarTasks((prev) => ({
            ...prev,
            [dateKey]: prev[dateKey] ? [...prev[dateKey], task] : [task],
        }));
        // setTasks((prev) => prev.filter((t) => t._id !== task._id));
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        dispatch(getStudyPlannerList());
    }, [])

    useEffect(() => {
        if (isCreate) {
            dispatch(getStudyPlannerList());
        }
        if (isUpdate) {
            dispatch(getStudyPlannerList());
        }
    }, [isUpdate, isCreate])
    
    useEffect(() => {
        if (!universities.length) {
          dispatch(getUniversitiesDropdown());
        }
        if (universityId && universityId !== "" ) {
            dispatch(getUniversityDegreeDropdown(universityId));
            setDegreeId("");
            setYearId("")
            setSubjectId("")
        }
    }, [universityId]);

    useEffect(() => {
        if (universityId && universityId !== "" && degreeId && degreeId !== "") {
            dispatch(getUniversityYearDropdown(universityId, degreeId));
            setYearId("")
            setSubjectId("")
        }
    }, [degreeId]);

    useEffect(() => {
        if (universityId && universityId !== "" && degreeId && degreeId !== "" && yearId && yearId !== "") {
            dispatch(getTopicsList(universityId, degreeId, yearId));
            setSubjectId("")
        }
    }, [yearId]);

    const getListOfEvents = (dataKey) => {
        if (studyPlannerList?.length > 0) {
            const list = studyPlannerList.filter((sp) => sp.date === dataKey)
            return list
        } else {
            return []
        }
    }

    return (
        <section className="study-planner">
            <Container fluid>
                <Row>
                    <h2 className="study-planner-heading">Study Planner</h2>
                </Row>
                <Row>
                    <Col md={12} sm={12}>
                        <div className="planner-board"> 
                            <div className="board-topic-list">
                                <div>
                                    <h4>Drag Topics onto the calender</h4>
                                    <select className="form-select" name="university" onChange={(e) => {
                                        e.preventDefault();
                                        setUniversityId(e.target.value)
                                    }}>
                                        <option selected disabled>Select University</option>
                                        {/* <option value="1">Computer Science</option>
                                        <option value="2">Medicine</option>
                                        <option value="3">Dentistry</option> */}
                                        {
                                            universities?.length > 0 && universities?.map((uni) => {
                                                return (
                                                    <option value={uni._id}>{uni.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <select className="form-select" name="degree" value={degreeId} onChange={(e) => {
                                        e.preventDefault();
                                        setDegreeId(e.target.value)
                                    }}>
                                        <option value="">Select Degree</option>
                                        {
                                            degree?.length > 0 && degree?.map((deg) => {
                                                return (
                                                    <option value={deg._id}>{deg.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <select className="form-select" name="year" value={yearId} onChange={(e) => {
                                        e.preventDefault();
                                        setYearId(e.target.value)
                                    }}>
                                        <option value="">Select Year</option>
                                        {
                                            years?.length > 0 && years?.map((yearDetail) => {
                                                return (
                                                    <option value={yearDetail._id}>{yearDetail.year}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <select className="form-select" name="subject" value={subjectId} onChange={(e) => {
                                        e.preventDefault();
                                        setSubjectId(e.target.value)
                                    }}>
                                        <option value="">Select Subject</option>
                                        {
                                            topics?.length > 0 && topics?.map((tp) => {
                                                return (
                                                    <option value={tp._id}>{tp.subjectName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    {
                                        topics?.length > 0 && subjectId && topics.filter((topic) => topic._id === subjectId).map((topic) => {
                                            return (
                                                <>
                                                    <p>{topic?.subjectName}</p>
                                                    <ul>
                                                        {topic?.topicsList?.length > 0 && topic?.topicsList.map((task) => {
                                                            return (
                                                            <li key={task?._id} draggable onDragStart={(event) => handleDragStart(event, task)} style={{ cursor: "grab" }}>
                                                                {dragIcon} {task?.topicName}
                                                            </li>
                                                        )})}
                                                    </ul>
                                                </>
                                            )
                                        })
                                    }
                                    {/* <p>Computer Science</p>
                                    <ul>
                                        {tasks.map((task) => (
                                            <li key={task} draggable onDragStart={(event) => handleDragStart(event, task)} style={{ cursor: "grab" }}>
                                                {dragIcon} {task}
                                            </li>
                                        ))}
                                    </ul> */}
                                </div>
                            </div>
                            <div className="board-calendar">
                                <div className="calendar-actions">
                                    <button className="btn_primary ms-auto">Study Planner</button>
                                    <div>
                                        <h5 className="me-auto">{new Intl.DateTimeFormat(locale, { month: "long", year: "numeric" }).format(currentDate)}</h5>
                                        <button onClick={handlePrevMonth} className="btn_primary">
                                            Previous
                                        </button>
                                        <button onClick={handleNextMonth} className="btn_primary">
                                            Next
                                        </button>
                                    </div>
                                </div>
                                <div className="calendar">
                                    <div>
                                        {weekdays.map((day) => (
                                            <div className="calendar-day" key={day}>
                                                {day}
                                            </div>
                                        ))}
                                        {calendarDays.map((day, index) => {
                                            const dateKey = day ? day.toISOString().split("T")[0] : null;
                                            return (
                                                <div key={index} className="calendar-date" onDragOver={handleDragOver} onDrop={(event) => handleDrop(event, dateKey)}>
                                                    <p style={{ color: `${moment(new Date(day)).format('L') === moment(new Date()).format('L') ? 'green' : ''}` }}>{day ? day.getDate() : ""}</p>
                                                    <ul>
                                                        {/* {(calendarTasks[dateKey] || []).map((task, i) => (
                                                            <>
                                                                <li key={i}>{task}
                                                                <button onClick={() => {
                                                                    console.log('click into the cancle')
                                                                }}> X </button></li>
                                                            </>
                                                        ))} */}
                                                        {
                                                            getListOfEvents(dateKey).map((task, i) => {
                                                                return (
                                                                <>
                                                                    <div className="d-flex">
                                                                        <li key={i} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            navigate(`/university/${task?.topicDetails?.subjectDetail?.universityId}/degree/${task?.topicDetails?.subjectDetail?.degreeId}/year/${task?.topicDetails?.subjectDetail?.yearId}/subject/${task?.topicDetails?.subjectId}`)
                                                                        }}>{task?.topicDetails?.topicName || "-"}</li>
                                                                        <button style={{ height:'25px', width: '25px', border: '' }} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            dispatch(updateStudyPlannerList(task._id));
                                                                        }}> X </button>
                                                                    </div>
                                                                </>
                                                            )})
                                                        }
                                                    </ul>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default StudyPlanner;
