import { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import * as userAction from "./redux/user/userAction";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Success from "./components/Success";
import Failure from "./components/Failure";
import FreePage from "./pages/auth/FreePage";
import Loader from "./components/Loader";
import arrowUp from "./assets/images/arrow-up.png"
import Universities from "./pages/Universities";
import UniversityDetail from "./pages/UniversityDetail";
import UniversityPage from "./pages/UniversityPage";
import StudyPlanner from "./pages/StudyPlanner";

const Layout = lazy(() => import("./pages/Layout"));
const Register = lazy(() => import("./pages/auth/Register"));
const Login = lazy(() => import("./pages/auth/Login"));
const Home = lazy(() => import("./pages/home/Home"));
const Blogs = lazy(() => import("./pages/blog/Blogs"));
const BlogDetail = lazy(() => import("./pages/blog/BlogDetail"));
const Subject = lazy(() => import("./pages/subject/Subject"));
const TopicDetail = lazy(() => import("./pages/subject/TopicDetail"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const FAQ = lazy(() => import("./pages/FAQ"));
const Account = lazy(() => import("./pages/Account"));
const TutionHub = lazy(() => import("./pages/TutionHub"));
const Subscription = lazy(() => import("./pages/subscription/Subscription"));
const BlurPage = lazy(() => import("./pages/BlurPage"));

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  if (!localStorage.getItem("token")) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <div className="donut"></div>
    </div>
  );
};

function App() {
  const location = useLocation();
  const ref = useRef(null);
  const [pageScrolled, setPageScrolled] = useState(false);

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      dispatch(userAction.getUser());
    }
  }, [location, token]);

  const handleScroll = () => {
    if (window.pageYOffset >= 500) {
      setPageScrolled(true)
    } else {
      setPageScrolled(false)
    }
    // console.log("window", window.pageYOffset)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleScroll, false);
    ref.current.complete();
  }, [location]);
  
  useEffect(() => {
    ref.current.continuousStart();
  }, []);
  
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <LoadingBar color="#990d0e" ref={ref} />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:resettoken" element={<ResetPassword />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/trail" element={<FreePage />} />
          <Route path="/success/:selectedPlan/:userId" element={<Success />} />
          <Route path="/success/:selectedPlan/:userId/:isTrial" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<BlogDetail key={location.key} />} />
            <Route path="/subject/topics" element={
              <ProtectedRoute>
                <Subject />
              </ProtectedRoute>
            } />
            <Route path="/subject/topicdetail/:id" element={
              <ProtectedRoute>
                <TopicDetail key={location.key} />
              </ProtectedRoute>
            } />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/account" element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            } />
            <Route path="/tutionhub/one_to_one" element={<TutionHub />} />
            <Route path="/tutionhub/course" element={<TutionHub />} />
            <Route path="/not/signin" element={<BlurPage forSignin />} />
            <Route path="/not/paid" element={<BlurPage forUpgrade />} />
            <Route path="/not/restPage" element={<BlurPage forRestPage />} />
            <Route path="/university" element={<Universities />} />
            <Route path="/tutionhub/course/:id" element={<UniversityDetail />} />
            {/* <Route path="/university/:id" element={<UniversityDetail />} /> */}
            <Route path="/university/:id" element={<UniversityPage />} />
            <Route path="/university/:uniId/degree/:degreeId/year/:yearId/subject/:id" element={
              // <ProtectedRoute>
                <TopicDetail key={location.key} />
              // </ProtectedRoute>
            } />
            <Route path="/study-planner" element={<StudyPlanner />} />
            <Route path="*" element={<Layout />} />
          </Route>
        </Routes>
      </Suspense>
      {}
      <button onClick={handleScrollToTop} className={`scroll_to_top${pageScrolled ? " show" : ""}`}>
        <img src={arrowUp} alt="arrow-up-icon" />
      </button>
    </>
  );
}

export default App;
