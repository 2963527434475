import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_TOPICS_LIST_REQUEST = "GET_TOPICS_LIST_REQUEST";
export const GET_TOPICS_LIST_SUCCESS = "GET_TOPICS_LIST_SUCCESS";
export const GET_TOPICS_LIST_FAILURE = "GET_TOPICS_LIST_FAILURE";

export const GET_TOPICS_PROGRESS_REQUEST = "GET_TOPICS_PROGRESS_REQUEST";
export const GET_TOPICS_PROGRESS_SUCCESS = "GET_TOPICS_PROGRESS_SUCCESS";
export const GET_TOPICS_PROGRESS_FAILURE = "GET_TOPICS_PROGRESS_FAILURE";

export const UPDATE_TOPICS_PROGRESS_REQUEST = "UPDATE_TOPICS_PROGRESS_REQUEST";
export const UPDATE_TOPICS_PROGRESS_SUCCESS = "UPDATE_TOPICS_PROGRESS_SUCCESS";
export const UPDATE_TOPICS_PROGRESS_FAILURE = "UPDATE_TOPICS_PROGRESS_FAILURE";

export const UPDATE_RATING_TO_TOPIC_REQUEST = "UPDATE_RATING_TO_TOPIC_REQUEST";
export const UPDATE_RATING_TO_TOPIC_SUCCESS = "UPDATE_RATING_TO_TOPIC_SUCCESS";
export const UPDATE_RATING_TO_TOPIC_FAILURE = "UPDATE_RATING_TO_TOPIC_FAILURE";

export const MARK_TOPIC_COMPLETED_REQUEST = "MARK_TOPIC_COMPLETED_REQUEST";
export const MARK_TOPIC_COMPLETED_SUCCESS = "MARK_TOPIC_COMPLETED_SUCCESS";
export const MARK_TOPIC_COMPLETED_FAILURE = "MARK_TOPIC_COMPLETED_FAILURE";

export const MARK_TOPIC_INCOMPLETED_REQUEST = "MARK_TOPIC_INCOMPLETED_REQUEST";
export const MARK_TOPIC_INCOMPLETED_SUCCESS = "MARK_TOPIC_INCOMPLETED_SUCCESS";
export const MARK_TOPIC_INCOMPLETED_FAILURE = "MARK_TOPIC_INCOMPLETED_FAILURE";

export const GET_MARK_TOPIC_STATUS_REQUEST = "GET_MARK_TOPIC_STATUS_REQUEST";
export const GET_MARK_TOPIC_STATUS_SUCCESS = "GET_MARK_TOPIC_STATUS_SUCCESS";
export const GET_MARK_TOPIC_STATUS_FAILURE = "GET_MARK_TOPIC_STATUS_FAILURE";

export const IS_TOPIC_UPDATE = "IS_TOPIC_UPDATE";

export const ASSIGN_TOPICS_TO_THE_USER_REQUEST =
  "ASSIGN_TOPICS_TO_THE_USER_REQUEST";
export const ASSIGN_TOPICS_TO_THE_USER_SUCCESS =
  "ASSIGN_TOPICS_TO_THE_USER_SUCCESS";
export const ASSIGN_TOPICS_TO_THE_USER_FAILURE =
  "ASSIGN_TOPICS_TO_THE_USER_FAILURE";

export const getTopicsList = (subjectId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TOPICS_LIST_REQUEST });
      const url = `${BASE_URL}/user/subject/get/topic/list/user/${subjectId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_TOPICS_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_TOPICS_LIST_FAILURE, payload: error.message });
    }
  };
};

export const getTopicsProgressCount = (topicId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TOPICS_PROGRESS_REQUEST });
      const url = `${BASE_URL}/topic/get/topic/${topicId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_TOPICS_PROGRESS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_TOPICS_PROGRESS_FAILURE, payload: error.message });
    }
  };
};

export const updateTopicsProgressCount = (topicId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_TOPIC_UPDATE" });
      dispatch({ type: UPDATE_TOPICS_PROGRESS_REQUEST });
      const url = `${BASE_URL}/topic/update/progress/bar/${topicId}/v1`;
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: UPDATE_TOPICS_PROGRESS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_TOPICS_PROGRESS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const assignTopicToTheUser = (topicId, assignedId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_TOPIC_UPDATE" });
      dispatch({ type: ASSIGN_TOPICS_TO_THE_USER_REQUEST });
      const url = `${BASE_URL}/user/subject/assign/topic/to/user/${topicId}/v1`;
      const response = await axios.post(
        url,
        { assignedId },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: ASSIGN_TOPICS_TO_THE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (
        error?.response?.data?.status == 401 ||
        error?.response?.data?.status == 403
      ) {
        window.location.href = "/login";
      }
      dispatch({
        type: ASSIGN_TOPICS_TO_THE_USER_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateRatingOfTopic = (topicId, assignedId, rating) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_TOPIC_UPDATE" });
      dispatch({ type: UPDATE_RATING_TO_TOPIC_REQUEST });
      const url = `${BASE_URL}/user/subject/rating/from/user/${topicId}/v1`;
      const response = await axios.put(
        url,
        { assignedId, rating },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: UPDATE_RATING_TO_TOPIC_SUCCESS,
        payload: response.data,
      });
      toast.success(
        `${rating === 2 ? "You have bookmarked this topic" : "You removed the bookmark from this topic"}`
      );
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_RATING_TO_TOPIC_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const markedTopicAsCompleted = (topicId, assignedId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_TOPIC_UPDATE" });
      dispatch({ type: MARK_TOPIC_COMPLETED_REQUEST });
      const url = `${BASE_URL}/user/subject/mark/completed/v1`;
      const response = await axios.put(
        url,
        { assignedId, topicId },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: MARK_TOPIC_COMPLETED_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: MARK_TOPIC_COMPLETED_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const markedTopicAsInCompleted = (topicId, assignedId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_TOPIC_UPDATE" });
      dispatch({ type: MARK_TOPIC_INCOMPLETED_REQUEST });
      const url = `${BASE_URL}/user/subject/mark/in/completed/v1`;
      const response = await axios.put(
        url,
        { assignedId, topicId },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: MARK_TOPIC_INCOMPLETED_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: MARK_TOPIC_INCOMPLETED_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const markedTopicStatus = (topicId, assignedId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MARK_TOPIC_STATUS_REQUEST });
      const url = `${BASE_URL}/user/subject/get/progress/${topicId}/${assignedId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_MARK_TOPIC_STATUS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_MARK_TOPIC_STATUS_FAILURE,
        payload: error.message,
      });
    }
  };
};
