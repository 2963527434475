import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
    getUniversitiesDropdown
} from "../redux/dropdown/dropdownAction";

const BASE_AWS_URL = process.env.REACT_APP_AWS_URL;
const Universities = () => {
    const dispatch = useDispatch();
    const { universities, degree, years } = useSelector(
        (state) => state.dropdownInfo
    );

    useEffect(() => {
        if (!universities?.length) {
            dispatch(getUniversitiesDropdown());
        }
    }, [dispatch, universities]);

    const navigate = useNavigate();
    console.log('universities', universities)
    return (
        <section className="universities_page">
            <Container>
                <h2 className="universities_heading">Universities</h2>
                <Row className="gx-5 gy-5">
                    {
                        universities?.length > 0 && universities.map((uni, ind) => {
                            return (
                                <Col md={6} sm={12}>
                                    <div className="universities_card" tabIndex={ind}>
                                        <img src={uni?.logo !== "" && uni.logo ? `${BASE_AWS_URL}${uni.logo}` : "https://bcassetcdn.com/public/blog/wp-content/uploads/2022/05/11161506/Harvard-University-Logo.png" }/>
                                        <div>
                                            <h6>{uni?.name}</h6>
                                            <p>{uni?.bio}</p>
                                            {/* <p>Delivered in Bandon, Co. Cork by our Head of Maths, Eoghan O'Leary. These grinds book out fast (25 students).</p> */}
                                            <div className="d-flex gap-3">
                                                <button className="btn_primary w-50" onClick={() => {
                                                    navigate(`/university/${uni._id}`)
                                                }}>Explore</button>
                                                {/* <button className="btn_primary w-50">Dentisty</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                    {/* <Col md={6} sm={12}>
                        <div className="universities_card">
                            <img src="https://bcassetcdn.com/public/blog/wp-content/uploads/2022/05/11161506/Harvard-University-Logo.png" />
                            <div>
                                <h6>Weekly Face-to-Face Grinds For H1 - H2 Hopefuls</h6>
                                <p>Delivered in Bandon, Co. Cork by our Head of Maths, Eoghan O'Leary. These grinds book out fast (25 students).</p>
                                <div className="d-flex gap-3">
                                    <button className="btn_primary w-50">Medicine</button>
                                    <button className="btn_primary w-50">Dentisty</button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} sm={12}>
                        <div className="universities_card">
                            <img src="https://bcassetcdn.com/public/blog/wp-content/uploads/2022/05/11161506/Harvard-University-Logo.png" />
                            <div>
                                <h6>Weekly Face-to-Face Grinds For H1 - H2 Hopefuls</h6>
                                <p>Delivered in Bandon, Co. Cork by our Head of Maths, Eoghan O'Leary. These grinds book out fast (25 students).</p>
                                <div className="d-flex gap-3">
                                    <button className="btn_primary w-50">Medicine</button>
                                    <button className="btn_primary w-50">Dentisty</button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} sm={12}>
                        <div className="universities_card">
                            <img src="https://bcassetcdn.com/public/blog/wp-content/uploads/2022/05/11161506/Harvard-University-Logo.png" />
                            <div>
                                <h6>Weekly Face-to-Face Grinds For H1 - H2 Hopefuls</h6>
                                <p>Delivered in Bandon, Co. Cork by our Head of Maths, Eoghan O'Leary. These grinds book out fast (25 students).</p>
                                <div className="d-flex gap-3">
                                    <button className="btn_primary w-50">Medicine</button>
                                    <button className="btn_primary w-50">Dentisty</button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} sm={12}>
                        <div className="universities_card">
                            <img src="https://bcassetcdn.com/public/blog/wp-content/uploads/2022/05/11161506/Harvard-University-Logo.png" />
                            <div>
                                <h6>Weekly Face-to-Face Grinds For H1 - H2 Hopefuls</h6>
                                <p>Delivered in Bandon, Co. Cork by our Head of Maths, Eoghan O'Leary. These grinds book out fast (25 students).</p>
                                <div className="d-flex gap-3">
                                    <button className="btn_primary w-50">Medicine</button>
                                    <button className="btn_primary w-50">Dentisty</button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} sm={12}>
                        <div className="universities_card">
                            <img src="https://bcassetcdn.com/public/blog/wp-content/uploads/2022/05/11161506/Harvard-University-Logo.png" />
                            <div>
                                <h6>Weekly Face-to-Face Grinds For H1 - H2 Hopefuls</h6>
                                <p>Delivered in Bandon, Co. Cork by our Head of Maths, Eoghan O'Leary. These grinds book out fast (25 students).</p>
                                <div className="d-flex gap-3">
                                    <button className="btn_primary w-50">Medicine</button>
                                    <button className="btn_primary w-50">Dentisty</button>
                                </div>
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </section>
    );
};

export default Universities;
