import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as planAction from "../redux/plan/planAction";

const Success = () => {
  const { selectedPlan, userId, isTrial } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(planAction.setPlanExpiry({ selectedPlan, userId, isTrial }));
  }, [selectedPlan]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div className="col-md-6 text-center">
        <div className="alert alert-success text-center">
          <h4 className="alert-heading">Payment Successful</h4>
        </div>
        <a href="/">Back to Home</a>
      </div>
    </div>
  );
};

export default Success;
