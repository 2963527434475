import React from "react";
import ProgressCard from "./ProgressCard";

const TopicCard = ({ imgUrl, onClick, title, progressCount, subjectId, freeType }) => {
  return (
    <div onClick={onClick} className="subject_card_component h-100">
      <div>
        <img className="h-75" src={imgUrl} alt="" />
        <ProgressCard progressCount={progressCount || 0} />
      </div>
      <p>{title} {freeType && (<span>Free</span>)}{freeType === false && (<span>Premium</span>)}</p>
    </div>
  );
};

export default TopicCard;
