import React, { useEffect } from "react";
import heroImg from "../assets/images/university-hero.png";
import { Col, Container, Row } from "react-bootstrap";
import TopicCard from "../components/common/subject/TopicCard";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    getUniversityDetails
} from "../redux/dropdown/dropdownAction";
import * as subjectAction from "../redux/subject/subjectAction";
import * as homeAction from "../redux/home/homeAction";
const BASE_AWS_URL = process.env.REACT_APP_AWS_URL;

const UniversityPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { universityDetail, universityDet, mySubjects } = useSelector(
        (state) => state.dropdownInfo
    );

    useEffect(() => {
        if (!universityDetail._id) {
            dispatch(getUniversityDetails(id));
        }
    }, []);

    console.log('universityDetail', universityDetail)
    console.log('universityDet', universityDet)

    return (
        <>
            <section className="university-hero-section">
                <img src={universityDet?.backgroundImage ? `${BASE_AWS_URL}${universityDet.backgroundImage}` : heroImg} />
                <div>
                    <h1>Welcome to {universityDet?.name}</h1>
                    <p>{universityDet?.bio}</p>
                    <div className="d-flex gap-3">
                        {/* <button className="btn_text_light">Try Free</button> */}
                        <button className="btn_primary_outline" onClick={() => {
                            navigate('/subscription')
                        }}> Get premium </button>
                        <button className="btn_light_outline" onClick={() => {
                            navigate('/contactus')
                        }}> Contact Us </button>
                    </div>
                </div>
            </section>
            <section className="py-5 mb-4">
                {
                    universityDetail?.length > 0 && universityDetail?.map((degree, degInd) => {
                        return (
                            <>
                                <Container fluid>
                                    <>
                                        <h2 className="university-subject-heading">{degree?.name}</h2>
                                        {
                                            degree?.universityyearsDetail?.length > 0 && degree?.universityyearsDetail.map((uniYear) => {
                                                return (
                                                    <>
                                                        <Container>
                                                            <h3 className="university-year-heading">{uniYear?.year}</h3>
                                                        </Container>
                                                        <Container>
                                                            <Row>
                                                                {
                                                                    uniYear?.subjectsList?.length > 0 && uniYear.subjectsList.map((subject) => {
                                                                        let progress = 0; // Default progress value
                                                                        const matchedSubject = mySubjects?.length > 0 && mySubjects.find(
                                                                            (mySubject) =>
                                                                                mySubject.assignedSubjects.toString() === subject._id.toString()
                                                                        );
                                                                        if (matchedSubject) {
                                                                            // If there's a match, set progress accordingly
                                                                            progress = Math.round(matchedSubject.overAllProgress) || 0;
                                                                        }
                                                                        return (
                                                                            <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                                                                                <TopicCard 
                                                                                    onClick={() => {
                                                                                        if (localStorage.getItem("token")) {
                                                                                            console.log('if')
                                                                                            dispatch(
                                                                                              homeAction.assignSubjectToTheUser(subject._id, navigate, id, degree._id, uniYear._id)
                                                                                            );
                                                                                            dispatch(
                                                                                                subjectAction.countSubjectOverallProgress(subject._id)
                                                                                            );
                                                                                        } else {
                                                                                            navigate(`/university/${id}/degree/${degree._id}/year/${uniYear._id}/subject/${subject._id}`)
                                                                                        }
                                                                                      }}
                                                                                imgUrl={`${BASE_AWS_URL}${subject?.subjectImage}`} progressCount={progress} freeType={subject?.isFree} title={`${subject?.subjectName}`} />
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                                {/* <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                                                                    <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Mathematics" />
                                                                </Col>
                                                                <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                                                                    <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Biology" />
                                                                </Col>
                                                                <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                                                                    <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Economics" />
                                                                </Col> */}
                                                            </Row>
                                                        </Container>
                                                    </>
                                                )
                                                })
                                        }
                                    </>
                                </Container>
                            </>
                        )
                    })
                }
                {/* <Container fluid>
                    <h2 className="university-subject-heading">Computer</h2>
                    <h3 className="university-year-heading">1st Year</h3>
                </Container>
                <Container>
                    <Row>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Mathematics" />
                        </Col>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Biology" />
                        </Col>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Economics" />
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <h3 className="university-year-heading">2nd Year</h3>
                </Container>
                <Container>
                    <Row>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Mathematics" />
                        </Col>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Biology" />
                        </Col>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Economics" />
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <h3 className="university-year-heading">3rd Year</h3>
                </Container>
                <Container>
                    <Row>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Mathematics" />
                        </Col>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Biology" />
                        </Col>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Economics" />
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <h3 className="university-year-heading">4th Year</h3>
                </Container>
                <Container>
                    <Row>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Mathematics" />
                        </Col>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Biology" />
                        </Col>
                        <Col xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} className="my-3">
                            <TopicCard imgUrl="https://picsum.photos/200" progressCount={43} title="Economics" />
                        </Col>
                    </Row>
                </Container> */}
            </section>
        </>
    );
};

export default UniversityPage;
