import * as coursesAction from "./courseAction";

export const coursesFeatureKey = "coursesInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  courses: [],
  coursesDetails: {},
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case coursesAction.GET_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case coursesAction.GET_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: payload.data,
      };
    case coursesAction.GET_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case coursesAction.GET_COURSE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case coursesAction.GET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        coursesDetails: payload.data,
      };
    case coursesAction.GET_COURSE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
