import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_STUDY_PLANNER_REQUEST = "GET_STUDY_PLANNER_REQUEST";
export const GET_STUDY_PLANNER_SUCCESS = "GET_STUDY_PLANNER_SUCCESS";
export const GET_STUDY_PLANNER_FAILURE = "GET_STUDY_PLANNER_FAILURE";

export const CREATE_STUDY_PLANNER_REQUEST = "CREATE_STUDY_PLANNER_REQUEST";
export const CREATE_STUDY_PLANNER_SUCCESS = "CREATE_STUDY_PLANNER_SUCCESS";
export const CREATE_STUDY_PLANNER_FAILURE = "CREATE_STUDY_PLANNER_FAILURE";

export const PUT_STUDY_PLANNER_REQUEST = "PUT_STUDY_PLANNER_REQUEST";
export const PUT_STUDY_PLANNER_SUCCESS = "PUT_STUDY_PLANNER_SUCCESS";
export const PUT_STUDY_PLANNER_FAILURE = "PUT_STUDY_PLANNER_FAILURE";


export const getStudyPlannerList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_STUDY_PLANNER_REQUEST });
      const url = `${BASE_URL}/studyPlanner/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_STUDY_PLANNER_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_STUDY_PLANNER_FAILURE, payload: error.message });
    }
  };
};

export const updateStudyPlannerList = (studyPlannerId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_TOPIC_UPDATE" });
      dispatch({ type: PUT_STUDY_PLANNER_REQUEST });
      const url = `${BASE_URL}/studyPlanner/remove/${studyPlannerId}/v1`;
      const response = await axios.delete(
        url,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: PUT_STUDY_PLANNER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: PUT_STUDY_PLANNER_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const createStudyPlannerList = (body) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_STUDY_PLANNER_REQUEST });
      const url = `${BASE_URL}/studyPlanner/create/v1`;
      const response = await axios.post(url, body, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CREATE_STUDY_PLANNER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: CREATE_STUDY_PLANNER_FAILURE,
        payload: error.message,
      });
    }
  };
};
