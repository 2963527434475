import * as topicAction from "./studyPlannerAction";

export const studyPlannerFeatureKey = "studyPlannerInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  studyPlannerList: [],
  isUpdate: false,
  isCreate: false
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // get topic list

    case topicAction.GET_STUDY_PLANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case topicAction.GET_STUDY_PLANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        studyPlannerList: payload.data,
      };
    case topicAction.GET_STUDY_PLANNER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        studyPlannerList: [],
      };

    // update ratings

    case topicAction.PUT_STUDY_PLANNER_REQUEST:
      return {
        ...state,
        loading: true,
        isUpdate: false
      };
    case topicAction.PUT_STUDY_PLANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdate: true
      };
    case topicAction.PUT_STUDY_PLANNER_FAILURE:
      return {
        ...state,
        loading: false,
        isUpdate: false
      };

    // mark subject completed

    case topicAction.CREATE_STUDY_PLANNER_REQUEST:
      return {
        ...state,
        loading: true,
        isCreate: false
      };
    case topicAction.CREATE_STUDY_PLANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        isCreate: true,
      };
    case topicAction.CREATE_STUDY_PLANNER_FAILURE:
      return {
        ...state,
        loading: false,
        isCreate: false
      };

    default:
      return state;
  }
};
