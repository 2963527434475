import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCourses, getCoursesDetails, courseBuyNow } from "../redux/courses/courseAction";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const BASE_AWS_URL = process.env.REACT_APP_AWS_URL;

const UniversityDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { courses, coursesDetails } = useSelector((state) => state.coursesInfo);
    const { id } = useParams();

    const [buyCourse, setBuyCourse] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        whatsappNo: "",
        studentName: "",
        tutorialCourseId: id,
    });

    const handleFormOnChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        dispatch(getCourses());
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(getCoursesDetails(id));
        }
    }, [dispatch, id]);

    const filteredCourses = courses.filter((course) => course._id !== id);

    console.log("coursesDetails", coursesDetails);

    function isValidEmail(email) {
        let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        return regex.test(email);
    }

    const buyNow = () => {
        if (formData?.whatsappNo?.length !== 10) {
            toast.error("Please fill proper mobile number");
            return;
        }
        if (!isValidEmail(formData?.email)) {
            toast.error("Please Enter Proper email.");
            return;
        }
        console.log("check");
        if (formData?.name && isValidEmail(formData?.email) && formData?.whatsappNo?.length === 10 && formData?.studentName) {
            dispatch(courseBuyNow(formData, setBuyCourse, setFormData));
            // setBuyCourse(false)
            // setFormData({
            //   name: "",
            //   email: "",
            //   whatsappNo: "",
            //   studentName: "",
            //   tutorialCourseId: id
            // })
        } else {
            toast.error("Please fill all the details.");
            return;
        }
    };
    return (
        <>
            <section className="university-detail-section">
                <Container>
                    <Row className="px-1 px-md-5 flex-column-reverse flex-lg-row">
                        <Col lg={6} md={12} sm={12}>
                            <div className="university-detail">
                                <h6>
                                    {coursesDetails?.degree} {coursesDetails?.subject && coursesDetails?.subject !== "" && `(${coursesDetails?.subject})`}
                                </h6>
                                <h2>{coursesDetails?.courseName}</h2>
                                <ul className="m-0 p-0">
                                    <li className="d-flex">
                                        <span className="w-50 fs-5 fw-bold"><i class="ri-presentation-fill"></i> Teacher</span>
                                        <span className="w-50 fs-5">{coursesDetails?.teacher}</span>
                                    </li>
                                    <li className="d-flex">
                                        <span className="w-50 fs-5 fw-bold"><i class="ri-timer-fill"></i> Duration</span>
                                        <span className="w-50 fs-5">{coursesDetails?.duration}</span>
                                    </li>
                                    <li className="d-flex">
                                        <span className="w-50 fs-5 fw-bold"><i class="ri-brain-2-fill"></i> Learning Mode</span>
                                        <span className="w-50 fs-5">{coursesDetails?.learingMode === "liveOnline" ? "Live Online" : "Recording"}</span>
                                    </li>
                                    <li className="d-flex">
                                        <span className="w-50 fs-5 fw-bold"><i class="ri-calendar-schedule-fill"></i> Day/Time</span>
                                        <span className="w-50 fs-5">{coursesDetails?.dayTime}</span>
                                    </li>
                                    <li className="d-flex">
                                        <span className="w-50 fs-5 fw-bold"><i class="ri-play-fill"></i> Start Date</span>
                                        <span className="w-50 fs-5">{coursesDetails?.date}</span>
                                    </li>
                                </ul>
                                <p className="mt-4">{coursesDetails?.shortDescription}</p>
                                <p>{coursesDetails?.longDescription}</p>
                                <button
                                    className="btn_primary w-100 mt-5"
                                    onClick={() => {
                                        setBuyCourse(true);
                                    }}
                                >
                                    Enroll now
                                </button>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <img className="university-detail-img" src={`${BASE_AWS_URL}${coursesDetails?.image}`} alt="course image" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="university-detail-courses-section">
                <Container>
                    <Col>
                        <h3 className="mb-5">Courses</h3>
                        {courses.length > 0 && (
                            <Row className="gx-4 gy-5">
                                {filteredCourses.map((course, index) => (
                                    <Col xxl={4} xl={4} lg={4} md={6} sm={12} key={index}>
                                        <div className="university-course-card" onClick={() => navigate(`/tutionhub/course/${course._id}`)} style={{ cursor: "pointer" }}>
                                            <div>
                                                <div>
                                                    {course?.degree && course?.degree !== "" && (
                                                        <>
                                                            <span className="tag-primary">{course?.degree}</span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {course?.subject && course?.subject !== "" && <span className="tag-dark">{course?.subject}</span>}
                                                </div>
                                                <img src={`${BASE_AWS_URL}${course.image}`} alt="Course Thumbnail" />
                                            </div>
                                            <h3>{course.courseName}</h3>
                                            <p>{course.shortDescription}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Container>
            </section>
            <Modal scrollable={false} className="buy_course_modal" size="xl" centered show={buyCourse}>
                <Modal.Body className="buy_course_box">
                    <div className="buy_course_box_detail w-100">
                        <Row>
                            <Col lg={5} md={12} sm={6}>
                                <div className="university-course-card">
                                    <div>
                                        <div className="ps-2 py-2">
                                            <div>
                                                {coursesDetails?.degree && coursesDetails?.degree !== "" && (
                                                    <>
                                                        <span className="tag-primary">{coursesDetails?.degree}</span>
                                                        <br />
                                                    </>
                                                )}
                                                {coursesDetails?.subject && coursesDetails?.subject !== "" && <span className="tag-dark">{coursesDetails?.subject}</span>}
                                                <h5>{coursesDetails?.courseName || ""}</h5>
                                                <h6>{coursesDetails?.tutorName || ""}</h6>
                                            </div>
                                        </div>
                                        <img src="https://t3.ftcdn.net/jpg/05/31/32/36/360_F_531323691_ai5HJlsq7BC7wChtR21DDmhunXC35YG6.jpg" />
                                    </div>
                                    <div className="buy_course_price">
                                        <span>Initial Price</span>
                                        <span className="text-end text-decoration-line-through">&nbsp;€{coursesDetails?.initialPrice || ""}</span>
                                        <p>Final Price</p>
                                        <p className="text-end">€{coursesDetails?.fixedPrice || ""}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={7} md={12}>
                                <div className="buy_course_detail">
                                    <h3 className="buy_course_name">Order Details: Course</h3>
                                    <p className="buy_course_desc">{coursesDetails?.courseName || ""} : {coursesDetails?.subject || ""} {coursesDetails?.degree ? `- ${coursesDetails?.degree}` : '' } {coursesDetails?.shortDescription ? `- ${coursesDetails?.shortDescription}` : '' } </p>
                                    {/* <p className="buy_course_include">Courses included</p>
                                    <p className="buy_course_desc">
                                        {coursesDetails?.givingLessonFor ? `${coursesDetails.givingLessonFor} :` : ""}
                                        {coursesDetails?.courseIncluded || ""}
                                    </p> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="buy_course_box_form w-100">
                        <Row>
                            <Col lg={1}></Col>
                            <Col lg={5}>
                                <div className="form-group my-3">
                                    <label className="mb-2">Name</label>
                                    <input type="name" name="name" className="form-control" placeholder="Enter Name" value={formData.name} onChange={(e) => handleFormOnChange(e)} required />
                                </div>
                                <div className="form-group my-3">
                                    <label className="mb-2">Whatsapp Number</label>
                                    <input type="number" name="whatsappNo" className="form-control" placeholder="Enter Phone Number" value={formData.waNumber} onChange={(e) => handleFormOnChange(e)} required />
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="form-group my-3">
                                    <label className="mb-2">Email</label>
                                    <input type="email" name="email" className="form-control" placeholder="Enter Email" value={formData.email} onChange={(e) => handleFormOnChange(e)} required />
                                </div>
                                <div className="form-group my-3">
                                    <label className="mb-2">Student Name</label>
                                    <input type="text" name="studentName" className="form-control" placeholder="Enter Student Name" value={formData.studentName} onChange={(e) => handleFormOnChange(e)} required />
                                </div>
                            </Col>
                            <Col lg={1}></Col>
                        </Row>
                        <Row className="mt-4">
                            <Col lg={1} md={12}></Col>
                            <Col xl={2} lg={3} md={8}>
                                <button className="btn_primary w-100 mt-3" onClick={buyNow}>
                                    Buy
                                </button>
                            </Col>
                            <Col xl={2} lg={3} md={4}>
                                <button onClick={() => setBuyCourse(false)} className="btn_primary_outline w-100 mt-3">
                                    Close
                                </button>
                            </Col>
                            <Col lg={1} md={12}></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UniversityDetail;
