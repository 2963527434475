import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_HOMEPAGE_PRODUCTS_REQUEST = "GET_HOMEPAGE_PRODUCTS_REQUEST";
export const GET_HOMEPAGE_PRODUCTS_SUCCESS = "GET_HOMEPAGE_PRODUCTS_SUCCESS";
export const GET_HOMEPAGE_PRODUCTS_FAILURE = "GET_HOMEPAGE_PRODUCTS_FAILURE";

export const ASSIGN_SUBJECT_TO_THE_USER_REQUEST =
  "ASSIGN_SUBJECT_TO_THE_USER_REQUEST";
export const ASSIGN_SUBJECT_TO_THE_USER_SUCCESS =
  "ASSIGN_SUBJECT_TO_THE_USER_SUCCESS";
export const ASSIGN_SUBJECT_TO_THE_USER_FAILURE =
  "ASSIGN_SUBJECT_TO_THE_USER_FAILURE";

export const getHomePageProducts = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_HOMEPAGE_PRODUCTS_REQUEST });
      const url = `${BASE_URL}/get/homepage/v1`;
      const response = await axios.get(url);
      dispatch({ type: GET_HOMEPAGE_PRODUCTS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_HOMEPAGE_PRODUCTS_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const assignSubjectToTheUser = (subjectId, navigate, uniId, degreeId, uniYearId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_SUBJECT_ASSIGNED" });
      dispatch({ type: ASSIGN_SUBJECT_TO_THE_USER_REQUEST });
      const url = `${BASE_URL}/user/subject/assign/subject/to/user/${subjectId}/v1`;
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: ASSIGN_SUBJECT_TO_THE_USER_SUCCESS,
        payload: response.data,
      });
      // navigate(`/subject/topicdetail/${subjectId}`, {
      //   state: { subjectId: subjectId },
      // });
      navigate(`/university/${uniId}/degree/${degreeId}/year/${uniYearId}/subject/${subjectId}`, {
        state: { subjectId: subjectId },
      })
    } catch (error) {
      dispatch({
        type: ASSIGN_SUBJECT_TO_THE_USER_FAILURE,
        payload: error.message,
      });
      if (localStorage.getItem("token")) {
        // navigate("/not/paid");
        toast.error(error?.response?.data?.message);
      } else {
        // navigate("/login");
      }
    }
  };
};
